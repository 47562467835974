<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row" v-if="isShowCustomer">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-maxlength
                                  maxlength="41"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>フリガナ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="nameKana"
                                  v-model="searchForm.nameKana"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isShowCustomer">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="email"
                                  v-email
                                  v-trim
                                  v-maxlength
                                  maxlength="255"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>電話番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  v-number
                                  name="telephone"
                                  v-model="searchForm.telephone"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="userId"
                                  v-trim
                                  v-maxlength
                                  maxlength="11"
                                  v-model="searchForm.userId"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>取引ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="36"
                                  name="transactionId"
                                  v-model="searchForm.transactionId"
                                  @blur="handleBlur($event, 'transactionId')"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="useTicketFlag || $permission.isGroup()">
                    <div class="row">
                      <div class="col" v-if="$permission.isGroup()">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <SearchSelect
                                ref="searchSelect"
                                :options="storeList"
                                @change-selection="setSelectedShopId"
                                :searchKeyMinLength="searchKeyLength"
                                :searchAction="searchAction"
                                :resetOptions="resetOptions"
                                :params="params"
                                :searchField="searchField"
                              />
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div v-if="useTicketFlag" class="col">
                        <FormRow>
                          <template v-slot:label>TICKET名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="ticketId" v-model="searchForm.ticketId">
                                  <option value="">選択してください</option>
                                  <option v-for="item in ticketList" :key="item.id" :value="item.id.toString()">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isSearchFormOpened">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>期間指定</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <ul class="listGrid ml-10">
                                <li class="listGrid-item mr-10">
                                  <label class="form-radio">すべて</label>
                                </li>
                                <li v-for="(item, index) in optionSpecifiedPeriod.all" :key="index" class="listGrid-item">
                                  <label class="form-radio">
                                    <input class="form-radio-input" type="radio" :value="item.value" v-model="searchForm.type" />
                                    <span class="form-radio-label">{{ item.label }}</span>
                                  </label>
                                </li>
                              </ul>
                              <ul class="listGrid ml-10 mt--2">
                                <li class="listGrid-item mr-10">
                                  <label class="form-radio">検索条件</label>
                                </li>
                                <li v-for="(item, index) in optionSpecifiedPeriod.condition" :key="index" class="listGrid-item">
                                  <label class="form-radio">
                                    <input class="form-radio-input" type="radio" :value="item.value" v-model="searchForm.type" />
                                    <span class="form-radio-label">{{ item.label }}</span>
                                  </label>
                                </li>
                              </ul>
                              <div class="form-content-row mt-2">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="addSearch">{{ isSearchFormOpened ? '閉じる' : '高度な検索' }}</button>
                  </li>
                  <li class="listGrid-item pos-end">
                    <ul class="listGrid">
                      <li class="listGrid-item">
                        <button class="btn btn-white" type="button" @click="reset">リセット</button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-main" type="button" @click="search">検索</button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>支払額合計</template>
                  <template v-slot:num>{{ historyPaymentTotal | comma }}</template>
                  <template v-slot:sup>円</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox :storeModule="storeModule" :dataTable="dataTable" :labels="labels" />
                </div>
                <Modal @close="closeModal('confirmModal')" v-if="confirmModal">
                  <template v-slot:headline>
                    支払履歴詳細
                  </template>
                  <template v-slot:body>
                    <section class="section">
                      <p class="title">取引情報</p>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>取引ID</th>
                            <td colspan="2">{{ historyDetail.id }}</td>
                          </tr>
                          <tr>
                            <th>{{ historyDetail.cancelDate ? 'キャンセル日時' : '支払日時' }}</th>
                            <td colspan="2">{{ historyDetail.cancelDate || historyDetail.createDate | fullDateTime }}</td>
                          </tr>
                          <tr class="table-child">
                            <th>支払金額</th>
                            <td>
                              <tr class="first-row">
                                <td v-if="historyDetail.chargedBreakdown">{{ historyDetail.shop.groupName }}</td>
                                <td>¥{{ historyDetail.amount | comma }}</td>
                              </tr>
                              <table v-if="useTicketFlag">
                                <caption class="table-child-title">
                                  {{ childTitle }}
                                </caption>
                                <tr v-for="(ticket, index) in historyDetail.tickets" :key="index">
                                  <th>
                                    <p class="pre-line">{{ endline(ticket.name, 10) }}</p>
                                  </th>
                                  <td>¥{{ ticket.amountBreakdown | comma }} x {{ ticket.quantity | comma }}枚</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <th>ステータス</th>
                            <td colspan="2">{{ historyStatus }}</td>
                          </tr>
                          <tr v-if="historyDetail.addAmountPoint">
                            <th v-if="historyDetail.isPointRelease">支払付与ポイント</th>
                            <th v-else>
                              支払付与予定<br/>
                              ポイント
                            </th>
                            <td colspan="2">{{ historyDetail.addAmountPoint | comma }}P</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                    <section class="section">
                      <p class="title">加盟店情報</p>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>店舗名</th>
                            <template v-if="historyDetail.shop">
                              <td>{{ historyDetail.shop.name }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                    <section class="section">
                      <p class="title">購入者情報</p>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>user ID</th>
                            <td colspan="2">{{ historyDetail.userId }}</td>
                          </tr>
                          <tr v-if="isShowCustomer">
                            <th>お名前</th>
                            <td colspan="2" v-if="historyDetail.customerDeleteDate">退会ユーザー</td>
                            <td colspan="2" v-else>{{ historyDetail.name }}</td>
                          </tr>
                          <tr>
                            <th rowspan="4">支払後残高</th>
                            <td colspan="2">¥{{ historyDetail.charged | comma }}</td>
                          </tr>
                          <tr v-if="historyDetail.chargedBreakdown">
                            <th colspan="2">使用できる金額</th>
                          </tr>
                          <tr v-for="item in historyDetail.chargedBreakdown" :key="item.id">
                            <th>{{ item.name }}</th>
                            <td>¥{{ item.amount | comma }} まで</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </template>
                  <template v-slot:footer>
                    <ul class="listGrid justify-content-end">
                      <li class="listGrid-item">
                        <button
                          class="btn btn-white"
                          type="button"
                          @click="closeModal('confirmModal')"
                        >
                          閉じる
                        </button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-red"
                          type="button"
                          :disabled="isCancelDisabled"
                          @click="showCancelPaymentPopup"
                        >
                          キャンセルし払い戻す
                        </button>
                      </li>
                    </ul>
                  </template>
                </Modal>
                <Modal @close="closeModal('cancelPaymentModal')" v-if="cancelPaymentModal">
                <template v-slot:headline>キャンセルし払い戻す</template>
                  <template v-slot:body>
                    <p class="description text-align-center">
                      この支払いをキャンセルとし払い戻し処理を行いますか？<br />
                      この操作は取り消すことができません。
                    </p>
                    <ul class="listGrid justify-content-center">
                      <li class="listGrid-item">
                        <button
                          class="btn btn-lg btn-white"
                          type="button"
                          @click="closeModal('cancelPaymentModal')"
                        >
                          いいえ
                        </button>
                      </li>
                      <li class="listGrid-item">
                        <ActionButton
                          class="btn btn-lg btn-red"
                          :handle-submit="cancelPayment"
                          button-text="はい"
                        />
                      </li>
                    </ul>
                  </template>
                </Modal>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :listLength="historyCount"
                  :searchConditions="searchConditions"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  ref="pagination"
                  :noGetPaginationList="isShopDetail || noGetPaginationList"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!-- [footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid selectBtn">
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-black"
              button-text="CSVダウンロード"
              :disabled="!historyList.length || isDisable"
              :handle-submit="downloadCSV"
            />
          </li>
          <li class="listGrid-item" v-if="isBackSummary">
            <button class="btn btn-white" @click="backToSummary">集計へ戻る →</button>
          </li>
        </ul>
      </template>
    </PageFooter>
    <Modal v-if="downloadCSVModal">
      <template v-slot:headline>CSVダウンロード</template>
      <template v-slot:body>
        <p class="description-csv">
          CSVファイルの準備が完了しました。<br />
          ［ダウンロード］ボタンよりダウンロードできます。
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="cancelDownloadCsv"
            >
              閉じる
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="downloadCsv"
              button-text="ダウンロード"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <!--[/footer] -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
import sort from '@/mixins/plugin/sort';
import modal from '@/mixins/plugin/modal';
import flatpickr from '@/mixins/plugin/flatpickr';
import search from '@/mixins/plugin/search';
import payment from '@/mixins/module/payment';
import download from '@/mixins/plugin/download';
import history from '@/mixins/module/history';
import { formatDateAndTime, formatLocalString, formatUploadDate, endline } from '@/helpers/formatData';
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import Modal from '@/components/Modal.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import { CommonConstants } from '@/constants/common';

export default {
  name: 'ShopHistory',
  data: function() {
    return {
      pageName: '支払履歴',
      authority: '',
      normalModulePath: 'history/getHistoryPayments',
      ticketModulePath: 'history/getHistoryPaymentsTicket',
      normalCSVPath: 'history/exportCSVHistoryPayment',
      ticketCSVPath: 'history/exportCSVHistoryPaymentTicket',
      searchConditions: {},
      storeModule: 'history',
      searchFields: ['name', 'email', 'transactionId', 'userId', 'shopId', 'type', 'startDate', 'endDate', 'ticketId', 'nameKana', 'telephone'],
      searchAction: 'shop/getShopList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      searchField: 'name',
      confirmModal: false,
      cancelPaymentModal: false,
      isDisable: false,
      downloadCSVModal: false,
      urlCsv: '',
      statusTimer: null,
      isBackSummary: false,
      skipTheFirstTime: 0,
      optionSpecifiedPeriod: {
        all: [
          {
            value: CommonConstants.TYPE_HISTORY_USE.CREATED_DATE,
            label: "支払日",
          },
          {
            value: CommonConstants.TYPE_HISTORY_USE.UPDATED_DATE,
            label: "更新日",
          },
        ],
        condition: [
          {
            value: CommonConstants.TYPE_HISTORY_USE.PAID_DATE,
            label: "支払い済み",
          },
          {
            value: CommonConstants.TYPE_HISTORY_USE.CANCEL_DATE,
            label: "キャンセル",
          },
        ],
      },
      initialSearchConditions: {
        type: 1,
      },
      endline,
      isSearchFormOpened: false,
    };
  },
  components: {
    FormRow,
    NumDisplay,
    Flatpickr,
    Modal,
    SearchSelect,
    PaginationLog,
    TableWithCheckbox,
  },
  mixins: [flatpickr, modal, sort, search, payment, download, history],
  computed: {
    ...mapGetters({
      historyList: 'history/historyPayments',
      historyCount: 'history/historyPaymentsCount',
      historyDetail: 'history/historyPaymentDetail',
      storeList: 'shop/shopList',
      infor: 'auth/infor',
      historyPaymentTotal: 'history/historyPaymentTotal',
      isShowCustomer: 'event/isShowCustomer',
      subdomain: 'auth/commonSubdomain',
      ticketList: 'history/ticketList',
      useTicketFlag: 'event/useTicketFlag',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
    }),
    params() {
      return {
        status: StatusConstants.shop.approved.value,
        withTrashed: 1,
        subdomain: this.subdomain,
      }
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    dataTable() {
      return (
        this.historyList &&
        this.historyList.map((item) => {
          return {
            id: endline(item.id, 18),
            userId: item.userId,
            name: {
              content: item?.customerDeleteDate ? '退会ユーザー' : endline(item.name || '', 20),
              routeName: 'ShopUserDetail',
              params: { id: item.customerId },
              isLink: this.$permission.isStoreOrGroup() ? (!item.customerDeleteDate && item.showCustomerFlag) : !item.customerDeleteDate,
            },
            amount: this.getAmount(item),
            shopName: endline(this.getShopName(item), 15),
            createDate: formatDateAndTime(item.createDate),
            updateDate: formatDateAndTime(item.updateDate),
            buttonLinks: [
              {
                content: '支払履歴詳細',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal('confirmModal', item, 'history/getHistoryPaymentDetail', this.subdomain),
              },
            ],
            ...(this.useTicketFlag ? { ticketName: this.extractTicketNameFromArray(item.ticketBreakdown) } : {})
          };
        })
      );
    },
    labels() {
      const labelArr = [
        { key: 'id', name: '取引ID', tdClass: 'pre-line mw-130' },
        { key: 'userId', name: 'user ID' },
        { key: 'name', name: 'お名前', notShow: !this.isShowCustomer, tdClass: 'pre-line' },
        { key: 'amount', name: '支払額', tdClass: 'pre-line', keyMultiple: 'ticketName' },
        { key: 'shopName', name: '利用店舗', tdClass: 'pre-line' },
        { key: 'ticketName', name: 'TICKET名', tdClass: 'pre-line mw-120', keyMultiple: 'ticketName', notShow: !this.useTicketFlag },
        { key: 'createDate', name: '支払日時', sortKey: 'createDate' },
        { key: 'updateDate', name: '更新日時', sortKey: 'updateDate' },
        { isButtonColumn: true },
      ];
      if (this.useTicketFlag) {
        this.movePositionOfAmount(labelArr);
      }
      return labelArr;
    },
    isShopDetail() {
      return !!this.$route.params?.shopDetail;
    },
    childTitle() {
      return (this.$permission.isStoreOrGroup() || this.isGmoOrOfficeLoginStoreAndStore)  ? '内訳' : '内訳（発行元）';
    }
  },
  methods: {
    extractTicketNameFromArray(data) {
      return this.customExtractTicketNameFromArray(data);
    },
    getAmount(item) {
      return this.useTicketFlag ? this.extractAmountFromArray(item.ticketBreakdown) : ('¥' + formatLocalString(item.amount));
    },
    extractAmountFromArray(data) {
      return this.customExtractAmountFromArray(data);
    },
    async downloadCSV() {
      this.isDisable = true;
      const response = await this.$store.dispatch(this.downloadCSVPath, {
        data: this.payloadDownloadCsv,
        subdomain: this.subdomain,
        directory: this.$route.params.directory,
      });
      if (response) {
        this.saveIdRequestExport(response.id)
        this.checkExportCsvStatus(response.id);
      }
    },
    async checkExportCsvStatus(id) {
      this.statusTimer = setTimeout(async () => {
        const response = await this.$store.dispatch('history/getStatusExportCSV', id);
        if (response) {
          switch(response.status) {
            case StatusConstants.exportCsvStatus.waiting:
              this.checkExportCsvStatus(id);
              break;
            case StatusConstants.exportCsvStatus.success:
              this.urlCsv = response.url;
              this.showModal('downloadCSVModal')
              break;
            case StatusConstants.exportCsvStatus.cancel:
              this.$message.showMessageError(response.error);
              this.closeModal('downloadCSVModal');
              this.removeIdRequestExport();
              break;
            default:
              break;
          }
        }
      }, 5000)
    },
    saveIdRequestExport(id) {
      localStorage.setItem('idRequestExport', id);
    },
    getIdRequestExport() {
      return localStorage.getItem('idRequestExport');
    },
    removeIdRequestExport() {
      localStorage.removeItem('idRequestExport');
    },
    async downloadCsv() {
      const response = await this.$store.dispatch('history/getStatusExportCSV', this.getIdRequestExport());
      if (response) {
        this.downloadUrlCsv(this.urlCsv);
      }
    },
    cancelDownloadCsv() {
      this.closeModal('downloadCSVModal');
      this.isDisable = false;
      this.removeIdRequestExport();
    },
    backToSummary() {
      this.$router.push({ name: 'ShopSummary', params: this.$route.params })
    },
    reset() {
      this.resetSearch();
      this.isBackSummary = false;
    },
    addSearch() {
      this.isSearchFormOpened = !this.isSearchFormOpened;
    },
  },
  mounted() {
    this.getTicketList({
      subdomain: this.subdomain,
    });
    if (this.getIdRequestExport()) {
      this.checkExportCsvStatus(this.getIdRequestExport())
    }
    if (this.$route.params?.shopDetail) {
      this.isBackSummary = true;
      if (!this.$route.params?.startDate && !this.$route.params?.endDate) {
        this.searchForm.startDate = formatUploadDate(new Date());
        this.searchForm.endDate = formatUploadDate(new Date());
      } else {
        this.searchForm.startDate = this.$route.params?.startDate;
        this.searchForm.endDate = this.$route.params?.endDate;
      }
      this.searchForm.shopId = [this.$route.params?.shopDetail?.id];
      this.searchForm.type = this.$route.params?.type ?? CommonConstants.CommonConstants.TYPE_HISTORY_USE.CREATED_DATE;
      if (this.$permission.isGroup()) {
        this.$refs.searchSelect.selected = {
          id: this.$route.params?.shopDetail?.id,
          name: this.$route.params?.shopDetail?.name,
        };
      }
      this.search();
    }
  },
  beforeDestroy() {
    clearTimeout(this.statusTimer);
  },
  watch: {
    'searchForm': {
      handler() {
        if (this.skipTheFirstTime > 0) {
          this.isBackSummary = false;
        }
        this.skipTheFirstTime = this.skipTheFirstTime + 1;
      },
      deep: true,
    },
    useTicketFlag(value){
      if (value) {
        this.getTicketList({
          subdomain: this.subdomain,
        });
      }
    },
  }
};
</script>
<style scoped>
  .table-child>td {
    padding: 0;
  }
  .table-child .first-row {
    display: flex;
  }
  .table-child .first-row td {
    flex: 1;
  }
  .table-child .first-row td:last-child {
    border-right: none !important;
  }
  .table-child-amount {
    padding: 16px;
    display: block;
  }
  .table-child table {
    width: 100%;
  }
  .table-child-title {
    padding: 16px;
    background: #f5f5fb;
    border-bottom: 1px solid #e1e2eb;
    text-align: start;
    font-weight: bold;
  }
  .table-child table tr td {
    border-right: none !important;
  }
  .table-child table tr:last-child td {
    border-bottom: none !important;
  }
  .table-child table tr:last-child th {
    border-bottom: none !important;
  }
</style>
